<template>
  <div>
    <b-overlay
      :show="loadingReclamation"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-col sm="8">
            <h6>Nombre tipo de reclamación</h6>
          </b-col>
          <b-col sm="2">
            <h6>id tipo de reclamación</h6>
          </b-col>
          <b-col sm="2">
            <h6>Tipo de reclamación</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8" class="d-flex text-center">
            <feather-icon icon="UserPlusIcon" size="20" class="mt-1" />
            <h6 class="mt-1 ml-2">{{ reclamation.name }}</h6>
          </b-col>
          <b-col sm="2" class="d-flex">
            <feather-icon icon="ClipboardIcon" size="20" class="mt-1" />
            <h6 class="mt-1 ml-2">{{ reclamation.id }}</h6>
          </b-col>
          <b-col sm="2" class="d-flex">
            <feather-icon icon="ClipboardIcon" size="20" class="mt-1" />
            <h6 class="mt-1 ml-2">{{ reclamation.type }}</h6>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-card>
      <template>
        <b-row>
          <b-col cols="12">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Buscar</label>
                  <b-form-input v-model="searchTerm" placeholder="Buscar" type="text" class="d-inline-block" />
                </div>
              </b-form-group>
            </div>
            <vue-good-table :columns="fields" :rows="rows" :search-options="{enabled: true, externalQuery: searchTerm }" :pagination-options="{ enabled: true, perPage: pageLength }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'financial'" class="text-nowrap">
                  <b-badge variant="light-danger" v-if="props.row.financial == 'unassigned'">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>unassigned</span>
                  </b-badge>
                  <b-badge variant="light-warning" v-if="props.row.financial == 'assigned'">
                    <feather-icon icon="UserIcon" class="mr-25" />
                    <span>assigned</span>
                  </b-badge>
                  <b-badge variant="light-success" v-if="props.row.financial == 'finished'">
                    <feather-icon icon="FlagIcon" class="mr-25" />
                    <span>finished</span>
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'legal'" class="text-nowrap">
                  <b-badge variant="light-danger" v-if="props.row.legal == 'unassigned'">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>unassigned</span>
                  </b-badge>
                  <b-badge variant="light-warning" v-if="props.row.legal == 'assigned'">
                    <feather-icon icon="UserIcon" class="mr-25" />
                    <span>assigned</span>
                  </b-badge>
                  <b-badge variant="light-success" v-if="props.row.legal == 'finished'">
                    <feather-icon icon="FlagIcon" class="mr-25" />
                    <span>finished</span>
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'technique'" class="text-nowrap">
                  <b-badge variant="light-danger" v-if="props.row.technique == 'unassigned'">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>unassigned</span>
                  </b-badge>
                  <b-badge variant="light-warning" v-if="props.row.technique == 'assigned'">
                    <feather-icon icon="UserIcon" class="mr-25" />
                    <span>assigned</span>
                  </b-badge>
                  <b-badge variant="light-success" v-if="props.row.technique == 'finished'">
                    <feather-icon icon="FlagIcon" class="mr-25" />
                    <span>finished</span>
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'status_actual_phase'" class="text-nowrap">
                  <b-badge variant="light-danger" v-if="props.row.status_actual_phase == 'unassigned'">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>unassigned</span>
                  </b-badge>
                  <b-badge variant="light-warning" v-if="props.row.status_actual_phase == 'assigned'">
                    <feather-icon icon="UserIcon" class="mr-25" />
                    <span>assigned</span>
                  </b-badge>
                  <b-badge variant="light-success" v-if="props.row.status_actual_phase == 'finished'">
                    <feather-icon icon="FlagIcon" class="mr-25" />
                    <span>finished</span>
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'actions'" class="text-nowrap">
                  <RouterLink class="user__more"
                    :to="{ path: `/auditory/glosa/${props.row.assignment_id}` }">
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary" class="btn-icon rounded-circle">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                  </RouterLink>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                    class="btn-icon rounded-circle ml-1" @click="openUpdateAssignment(props.row)">
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :id="`form-item-settings-icon-${props.row.assignment_id}`" variant="outline-danger"
                    class="btn-icon rounded-circle ml-1">
                    <feather-icon size="16" icon="TrashIcon" class="cursor-pointer text-danger" />
                  </b-button>
                  <div class="d-flex flex-column justify-content-between py-50 px-25">
                    <b-popover :ref="`form-item-settings-popover-${props.row.assignment_id}`"
                      :target="`form-item-settings-icon-${props.row.assignment_id}`" triggers="click" placement="lefttop"
                      variant="danger">
                      <template #title>
                        <span>Eliminar</span>
                      </template>
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="12">
                            <p>¡Esta a punto de eliminar esta asignación!</p>
                          </b-col>
                          <b-col cols="12" class="d-flex justify-content-between mt-1">
                            <b-button variant="outline-danger" @click="deleteAssignment(props.row.assignment_id)">
                              Eliminar
                            </b-button>
                            <b-button variant="outline-secondary"
                              @click="() => { $refs[`form-item-settings-popover-${props.row.assignment_id}`].$emit('close') }">
                              Cancelar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-popover>
                  </div>
                </span>
              </template>
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select v-model="pageLength" :options="['5', '10', '50', '100', '500']" class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap ">{{ props.total }} registros encontrados </span>
                  </div>

                  <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                      align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </template>
    </b-card>
    <b-modal
      ref="modal-assignment"
      :title="'Editar asignación concept ' + assignmentForm.conceptId"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer 
        ref="assignmentRulesValidation"
      >
        <b-form ref="assignmentForm">
          <validation-provider
            #default="{ errors }"
            name="Lote"
            rules="required"
          >
            <b-form-group
              :state="errors.length > 0 ? false : null"
              :class="errors.length > 0 ? 'is-invalid' : null" 
            >
              <label for="batch">Lote:</label>
              <v-select
                v-model="assignmentForm.assignmentBatchId"
                label="id"
                :reduce="val => val.id"
                :options="listAssignmentBatches"
                placeholder="Seleccionar"
                trim 
                clearable
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="Analista"
            rules="required"
          >
            <b-form-group
              :state="errors.length > 0 ? false : null"
              :class="errors.length > 0 ? 'is-invalid' : null" 
            >
              <label for="user">Analista:</label>
              <v-select
                v-model="assignmentForm.userId"
                label="name"
                :reduce="val => val.id"
                :options="usersList"
                placeholder="Seleccionar"
                trim 
                clearable
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="estado"
            rules="required"
          >
            <b-form-group
              :state="errors.length > 0 ? false : null"
              :class="errors.length > 0 ? 'is-invalid' : null" 
            >
              <label for="qualityChecker">Estado:</label>
              <v-select
                v-model="assignmentForm.status"
                label="title"
                :reduce="val => val.value"
                :options="statusAssignmentOptions"
                placeholder="Seleccionar"
                trim 
                clearable
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <hr class="invoice-spacing mt-5">
          <b-form-group class="mt-2">
            <b-row>
              <b-col cols="12" md="6">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  block
                  @click="closeModalAssignment"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  @click="updateAssignment"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Guardar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
    
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol, BTable, BInputGroup, BInputGroupAppend, BPopover, BOverlay, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es';
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BPopover,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    localize,
    BOverlay,
    BForm,
  },
  data() {
    return {
      pageLength: 5,
      perPage: 5,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [],
      items: [],
      reclamationTypeId: this.$route.params && this.$route.params.reclamationTypeId,
      rows: [],
      searchTerm: '',
      reclamation: {
        name: null,
        id: null,
        type: null,
      },
      usersList: [],
      assignmentForm: {
        id: null,
        userId: null,
        assignmentBatchId: null,
        status: null,
        conceptId: null,
      },
      statusAssignmentOptions: [
        {
          title: 'Sin Asignar',
          value: 'unassigned',
        },
        {
          title: 'Asignado',
          value: 'assigned',
        },
        {
          title: 'Finalizado',
          value: 'finished',
        }
      ],
      listAssignmentBatches: [],
      loadingReclamation: false,
    }
  },
  directives: {
    Ripple,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    fetchCreditDetail() {
      this.loadingReclamation = true
      this.$http.get('/coordination/assignment/reclamation-detail-all/' + this.reclamationTypeId).then((response) => {
        this.loadingReclamation = false
        this.rows = response.data.rows
        this.fields = response.data.fields
        this.reclamation = response.data.reclamationType
        this.totalRows = response.data.total
      })
    },
    showGlosa(data) {
      this.$router.push('/auditory/glosa/' + data)
    },
    deleteAssignment(assignmentId) {
      this.$http.post('/coordination/assignment/delete/' + assignmentId).
      then((response) => {
        this.$refs[`form-item-settings-popover-${assignmentId}`].$emit('close')
        this.fetchCreditDetail()
        this.$swal({
          title: response.data.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        });
      })
      .catch (error => {
        this.$swal({
          title: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        });
      })
    },
    openModalAssignment() {
      this.$refs['modal-assignment'].show()
    },
    closeModalAssignment() {
      this.$refs['modal-assignment'].hide()
      this.$refs.assignmentRulesValidation.reset()
      this.assignmentResetForm()
    },
    assignmentResetForm() {
      this.assignmentForm = {
        id: null,
        userId: null,
        assignmentBatchId: null,
        status: null,
        conceptId: null,
      }
    },
    getUsersList() {
      this.$http.get('/coordination/assignment/get-quality-checker').then((response) => {
        this.usersList = response.data.qualityCheckerList
      })
    },
    getListAssignmentBatches() {
      this.$http.get('/coordination/assignment/list-assignment-batches').then((response) => {
        this.listAssignmentBatches = response.data.listAssignmentBatches
      })
    },
    openUpdateAssignment(assignment) {
      this.assignmentForm = {
        id: assignment.assignment_id,
        userId: assignment.user_id,
        assignmentBatchId: assignment.assignment_batch_id,
        status: assignment.status_actual_phase,
        conceptId: assignment.concept_id,
      }
      this.openModalAssignment()
    },
    updateAssignment() {
      localize('es', es)
      this.$refs.assignmentRulesValidation.validate().then(success => {
        if(success){
          this.$http.post('coordination/assignment/update-assignment', this.assignmentForm).then(response => {
            try {
              this.closeModalAssignment()
              this.fetchCreditDetail()
              this.$swal({
                title: response.data.message,
                icon: 'success',
                customClass: {
                      confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });

            } catch (error) {
              this.$swal({
                title: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
            }
          })
        }
      })
    },
  },
  created() {
    this.fetchCreditDetail()
    this.getUsersList()
    this.getListAssignmentBatches()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';


</style>
<style scoped lang="css">
.vgt-left-align {
  white-space: nowrap !important;
}

</style>
